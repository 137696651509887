{
  "name": "connect-my-club",
  "version": "3.2.19",
  "build": "367",
  "author": "connectMyClub",
  "homepage": "https://members.connectmyclub.co.uk",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --watch  --configuration development",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint",
    "ionic:build": "npm run build",
    "ionic:serve": "npm run start"
  },
  "config": {
    "ionic_webpack": "./config/webpack.config.js"
  },
  "private": true,
  "dependencies": {
    "@angular/cdk": "18.2.1",
    "@angular/common": "^18.2.1",
    "@angular/core": "^18.2.1",
    "@angular/forms": "^18.2.1",
    "@angular/google-maps": "^18.2.1",
    "@angular/platform-browser": "^18.2.1",
    "@angular/platform-browser-dynamic": "^18.2.1",
    "@angular/router": "^18.2.1",
    "@angular/service-worker": "^18.2.1",
    "@awesome-cordova-plugins/calendar": "6.8.0",
    "@awesome-cordova-plugins/core": "6.8.0",
    "@capacitor-community/file-opener": "6.0.1",
    "@capacitor-community/in-app-review": "^6.0.0",
    "@capacitor-community/stripe": "^6.0.2",
    "@capacitor-firebase/messaging": "^6.2.0",
    "@capacitor/android": "6.1.2",
    "@capacitor/app": "6.0.1",
    "@capacitor/browser": "6.0.2",
    "@capacitor/camera": "^6.0.0",
    "@capacitor/configure": "2.0.10",
    "@capacitor/core": "6.1.2",
    "@capacitor/device": "^6.0.0",
    "@capacitor/dialog": "^6.0.0",
    "@capacitor/filesystem": "^6.0.0",
    "@capacitor/geolocation": "^6.0.0",
    "@capacitor/haptics": "^6.0.0",
    "@capacitor/ios": "6.1.2",
    "@capacitor/keyboard": "^6.0.0",
    "@capacitor/network": "^6.0.0",
    "@capacitor/preferences": "^6.0.0",
    "@capacitor/share": "^6.0.0",
    "@capacitor/splash-screen": "^6.0.2",
    "@capacitor/status-bar": "^6.0.0",
    "@capawesome/capacitor-app-update": "^6.0.0",
    "@capawesome/capacitor-badge": "6.0.0",
    "@connectmyclub/capacitor-healthkit": "git+https://oauth2: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@github.com/connectMyClub/capacitor-healthkit-main.git",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-svg-core": "^6.4.2",
    "@fortawesome/free-brands-svg-icons": "^6.4.2",
    "@fortawesome/free-solid-svg-icons": "^6.7.1",
    "@fortawesome/pro-duotone-svg-icons": "^6.4.2",
    "@fortawesome/pro-light-svg-icons": "6.6.0",
    "@fortawesome/pro-regular-svg-icons": "6.6.0",
    "@ionic/angular": "^8.2.5",
    "@ionic/cli": "^7.2.0",
    "@ionic/core": "^8.2.5",
    "@ionic/pwa-elements": "^3.2.2",
    "@stripe/stripe-js": "4.3.0",
    "@types/google-maps": "^3.2.3",
    "autolinker": "4.0.0",
    "capacitor-blob-writer": "1.1.17",
    "capacitor-ios-autofill-save-password": "3.0.0",
    "capacitor-native-settings": "6.0.1",
    "chart.js": "4.4.4",
    "cordova": "12.0.0",
    "cordova-browser": "7.0.0",
    "cordova-plugin-calendar": "5.1.6",
    "date-fns": "^2.3.0",
    "firebase": "^10.14.1",
    "hammerjs": "^2.0.8",
    "ngx-linky": "^4.0.0",
    "plugin": "^0.3.3",
    "rxjs": "7.8.1",
    "save": "^2.9.0",
    "stripe-pwa-elements": "^2.1.0",
    "swiper": "8.4.7",
    "tslib": "^2.2.0",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.1",
    "@angular-eslint/builder": "18.3.0",
    "@angular-eslint/eslint-plugin": "18.3.0",
    "@angular-eslint/eslint-plugin-template": "18.3.0",
    "@angular-eslint/template-parser": "18.3.0",
    "@angular/cli": "^18.2.1",
    "@angular/compiler": "^18.2.1",
    "@angular/compiler-cli": "^18.2.1",
    "@angular/language-service": "18.2.10",
    "@capacitor/assets": "^3.0.5",
    "@capacitor/cli": "6.1.2",
    "@ionic/angular-toolkit": "^11.0.1",
    "@ionic/lab": "3.2.15",
    "@types/jasmine": "5.1.4",
    "@types/jasminewd2": "2.0.13",
    "@types/node": "22.5.0",
    "@typescript-eslint/eslint-plugin": "8.3.0",
    "@typescript-eslint/parser": "8.3.0",
    "eslint": "8.57.0",
    "eslint-plugin-import": "2.28.1",
    "eslint-plugin-jsdoc": "46.8.2",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "jasmine-core": "5.1.1",
    "jasmine-spec-reporter": "7.0.0",
    "karma": "6.4.2",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "2.2.1",
    "karma-coverage-istanbul-reporter": "~3.0.2",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "protractor": "~7.0.0",
    "ts-node": "10.9.2",
    "typescript": "5.5.4"
  },
  "description": "connectMyClub project",
  "browserslist": [
    "Chrome >=89",
    "ChromeAndroid >=89",
    "Firefox >=75",
    "Edge >=89",
    "Safari >=15",
    "iOS >=15"
  ],
  "packageManager": "yarn@1.22.22+sha512.a6b2f7906b721bba3d67d4aff083df04dad64c399707841b7acf00f6b133b7ac24255f2652fa22ae3534329dc6180534e98d17432037ff6fd140556e2bb3137e"
}
